import "./style.css";
import * as dat from "lil-gui";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import * as TWEEN from "@tweenjs/tween.js";
import { Raycaster, Texture, Vector3 } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import {gsap} from 'gsap';

/**
 * Base
 */
// Debug
const debugObject = {};
const gui = new dat.GUI({
  width: 250,
});
gui.hide();

//Parameters
let axes = false;
let menu = true;

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

/**
 * Overlay
 */
 const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1)
 const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  uniforms:
  {
    uAlpha: {value: 1}
  },
  vertexShader: `
      void main()
      {
          gl_Position = vec4(position, 1.0);
      }
  `,
  fragmentShader: `
      uniform float uAlpha;
      void main()
      {
          gl_FragColor = vec4(0.349, 0.337, 0.913, uAlpha);
      }
  `
})
 const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
 scene.add(overlay)

/**
 * Helpers Base
 */
if(axes){
  const axesHelper = new THREE.AxesHelper(17);
  scene.add( axesHelper );
}

/*
* Util Functions
*/
const loadingBarElement = document.querySelector('.loading-bar');
const loadingLogoElement = document.querySelector('.loading-logo');
const loadingNumberElement = document.querySelector('.number-progress');
const poweredByElement = document.querySelector('.poweredBy');
const notWorkElement = document.querySelector('.notWork');
let device = '';
if (/webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) { // Android|
  device = false;
  loadingBarElement.classList.add('ended');
  loadingLogoElement.classList.add('ended');
  loadingNumberElement.classList.add('ended');
  poweredByElement.classList.add('ended');
  gsap.to(notWorkElement, {duration: 0.3, opacity: 1})
} else {
  device = true;
  gsap.to(notWorkElement, {duration: 0.3, display: "none"})
}

console.log(device)
/**
 * Loaders
 */
//Manager Loading

const loadingManager = new THREE.LoadingManager(
  //Loaded
  () => {
    gsap.delayedCall(0.3, () => {
      gsap.to(overlayMaterial.uniforms.uAlpha, {duration: 3, value: 0})
      loadingBarElement.classList.add('ended');
      loadingLogoElement.classList.add('ended');
      loadingNumberElement.classList.add('ended');
      loadingBarElement.style.transform = '';
      //howToUseElement.style.opacity = 1;
      gsap.to(menuElement, {duration: 1, ease: "back.inOut(1)", x: 0})
    })
    setTimeout(toggleMenu, 4500)
  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
      const progressRatio = itemsLoaded / itemsTotal;
      const progressPercent = progressRatio * 100;
      loadingNumberElement.innerHTML = progressPercent.toFixed() + "%";
      loadingBarElement.style.transform = `scaleX(${progressRatio})`;
  }
)
// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager);

// Draco Loader
const dracoloader = new DRACOLoader(loadingManager);
dracoloader.setDecoderPath("draco/");

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoloader);

/**
 * Textures
 */
if(device){
  const backedEntorno = textureLoader.load("/texturas/baked_entorno.jpg");
  backedEntorno.flipY = false;
  //backedEntorno.encoding = THREE.sRGBEncoding

  const backedPlata1 = textureLoader.load("/texturas/baked_PLATA1.jpg");
  backedPlata1.flipY = false;
  //backedPlata1.encoding = THREE.sRGBEncoding

  const backedPlata2 = textureLoader.load("/texturas/baked_PLATA2.jpg");
  backedPlata2.flipY = false;

  const backedPlata3 = textureLoader.load("/texturas/baked_PLATA3.jpg");
  backedPlata3.flipY = false;

  const backedPlata4 = textureLoader.load("/texturas/baked_PLATA4.jpg");
  backedPlata4.flipY = false;

  const backedPlata5 = textureLoader.load("/texturas/baked_PLATA5.jpg");
  backedPlata5.flipY = false;

  const backedPlata6 = textureLoader.load("/texturas/baked_PLATA6.jpg");
  backedPlata6.flipY = false;

  const backedPlata7 = textureLoader.load("/texturas/baked_PLATA7.jpg");
  backedPlata7.flipY = false;

  const backedPlata8 = textureLoader.load("/texturas/baked_PLATA8.jpg");
  backedPlata8.flipY = false;


  const backedVIP1 = textureLoader.load("/texturas/baked_VIP1.jpg");
  backedVIP1.flipY = false;

  const backedVIP2 = textureLoader.load("/texturas/baked_VIP2.jpg");
  backedVIP2.flipY = false;

  const backedVIP3 = textureLoader.load("/texturas/baked_VIP3.jpg");
  backedVIP3.flipY = false;

  const backedVIP4 = textureLoader.load("/texturas/baked_VIP4.jpg");
  backedVIP4.flipY = false;

  /**
   * Material
   */

  //const bakedMaterial = new THREE.MeshStandardMaterial()
  const bakedMaterialEntorno = new THREE.MeshBasicMaterial({map: backedEntorno});

  const bakedMaterialPlata1 = new THREE.MeshBasicMaterial({ map: backedPlata1 });
  const bakedMaterialPlata2 = new THREE.MeshBasicMaterial({ map: backedPlata2 });
  const bakedMaterialPlata3 = new THREE.MeshBasicMaterial({ map: backedPlata3 });
  const bakedMaterialPlata4 = new THREE.MeshBasicMaterial({ map: backedPlata4 });
  const bakedMaterialPlata5 = new THREE.MeshBasicMaterial({ map: backedPlata5 });
  const bakedMaterialPlata6 = new THREE.MeshBasicMaterial({ map: backedPlata6 });
  const bakedMaterialPlata7 = new THREE.MeshBasicMaterial({ map: backedPlata7 });
  const bakedMaterialPlata8 = new THREE.MeshBasicMaterial({ map: backedPlata8 });

  const bakedMaterialVIP1 = new THREE.MeshBasicMaterial({ map: backedVIP1 });
  const bakedMaterialVIP2 = new THREE.MeshBasicMaterial({ map: backedVIP2 });
  const bakedMaterialVIP3 = new THREE.MeshBasicMaterial({ map: backedVIP3 });
  const bakedMaterialVIP4 = new THREE.MeshBasicMaterial({ map: backedVIP4 });


  /**
   * Feria 3D
   */

  gltfLoader.load("/objetos/entorno.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialEntorno;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/PLATA1.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata1;
      //console.log(child)
      if(child.name === 'mesita'){
        //console.log(child.position);
      }
    });
    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/PLATA2.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata2;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/PLATA3.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata3;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/PLATA4.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata4;
      //console.log(child.position);

    });

    scene.add(gltf.scene);
  });
  gltfLoader.load("/objetos/PLATA5.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata5;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/PLATA6.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata6;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/PLATA7.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata7;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/PLATA8.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialPlata8;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/VIP1.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialVIP1;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/VIP2.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialVIP2;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/VIP3.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialVIP3;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });

  gltfLoader.load("/objetos/VIP4.glb", (gltf) => {
    gltf.scene.traverse((child) => {
      child.material = bakedMaterialVIP4;
      //console.log(child)
    });

    scene.add(gltf.scene);
  });
}



/**
 * Points of interest
 */
const raycaster = new Raycaster();

var boxGeometry = new THREE.BoxGeometry(2, 2, 2);
var boxMaterial = new THREE.MeshBasicMaterial({visible: false}); //visible: true
var objetivo = new THREE.Mesh(boxGeometry, boxMaterial);
scene.add(objetivo);
/*
const points = [
  {
    position: new THREE.Vector3(-2.2, 1.6, 15),
    element: document.querySelector(".point-0"),
    animation: new THREE.Vector3(-4.91, 1.2, 1.7),
  },
];
*/

/**
 * Menu of interest
 */
const optionsMenu = [
  {
    element: document.querySelector(".plata-1"),
    animation: new THREE.Vector3(-5.53, 3.2, 15.90),
    lookAt: new THREE.Vector3(-0.80, 0.3, 13),
    menuStand: 'sanofi',
  },
  {
    element: document.querySelector(".plata-4"),
    animation: new THREE.Vector3(5.53, 3.2, 15.90),
    lookAt: new THREE.Vector3(0.80, 0.3, 13),
    menuStand: 'nutricia',
  },
  /*{
    element: document.querySelector(".plata-6"),
    animation: new THREE.Vector3(-5.53, 3.2, -15.90),
    lookAt: new THREE.Vector3(-0.80, 0.3, -13),
    menuStand: 'biocodex',
  },
  */
  {
    element: document.querySelector(".plata-5"),
    animation: new THREE.Vector3(-5.53, 3.2, -4),
    lookAt: new THREE.Vector3(-0.80, 0.3, -7),
    menuStand: 'takeda',
  },
  {
    element: document.querySelector(".plata-8"),
    animation: new THREE.Vector3(5.53, 3.2, -4),
    lookAt: new THREE.Vector3(0.80, 0.3, -7),
    menuStand: 'livanova',
  },
  {
    element: document.querySelector(".plata-7"),
    animation: new THREE.Vector3(5.53, 3.2, -15.90),
    lookAt: new THREE.Vector3(0.80, 0.3, -13),
    menuStand: 'eisai',
  },
  {
    element: document.querySelector(".vip-1"),
    animation: new THREE.Vector3(-10.75, 6.55, 21.69),
    lookAt: new THREE.Vector3(-16.59, 0.1, 10),
    menuStand: 'angelini',
  },
  {
    element: document.querySelector(".vip-2"),
    animation: new THREE.Vector3(-20.75, 6.55, -21.69),
    lookAt: new THREE.Vector3(-16.59, 0.1, -10),
    menuStand: 'jazz',
  },
  {
    element: document.querySelector(".vip-3"),
    animation: new THREE.Vector3(10.75, 6.55, -21.69),
    lookAt: new THREE.Vector3(16.59, 0.1, -10),
    menuStand: 'ucb',
  },
  /*
  {
    element: document.querySelector(".vip-4"),
    animation: new THREE.Vector3(20.75, 6.55, 21.69),
    lookAt: new THREE.Vector3(16.59, 0.1, 10),
  },
  */
];


//console.log(document.querySelector('.point-0'))

/**
 * Lights
 */
/*
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)

debugObject.ambientLight = ambientLight

const directionalLight = new THREE.DirectionalLight('#ffffff', 0.6)
directionalLight.position.set(20, 20, 20)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)

debugObject.directionalLight = directionalLight

const pointLight = new THREE.PointLight('#decece', 0.7)
pointLight.position.set(0, 3, 0)

debugObject.pointLight = pointLight
*/

//scene.add(ambientLight, pointLight, directionalLight)
//scene.add(ambientLight)
/*
const debugLightFolder = gui.addFolder('Light')
debugLightFolder.add(debugObject.ambientLight, 'intensity').min(0).max(1).step(0.001).name('IntensityAmbienLight')
debugLightFolder.addColor(debugObject.ambientLight, 'color').name('ColorAmbienLight')
debugLightFolder.add(debugObject.pointLight, 'intensity').min(0).max(1).step(0.001).name('IntensityPointLight')
debugLightFolder.addColor(debugObject.pointLight, 'color').name('ColorPointLight')
debugLightFolder.add(debugObject.directionalLight, 'intensity').min(0).max(1).step(0.001).name('IntensityDirectionalLight')
debugLightFolder.addColor(debugObject.directionalLight, 'color').name('ColorDirectionalLight')
debugLightFolder.open(false)
*/

/**
 * Helper Light
 */
/*
const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight)
const pointLightHelper = new THREE.PointLightHelper( pointLight, 1)
*/

//scene.add(directionalLightHelper, pointLightHelper)

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
};

window.addEventListener("resize", () => {
  // Update sizes
  sizes.width = window.innerWidth;
  sizes.height = window.innerHeight;

  // Update camera
  camera.aspect = sizes.width / sizes.height;
  camera.updateProjectionMatrix();

  // Update renderer
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  45,
  sizes.width / sizes.height,
  0.1,
  500
);
camera.position.set(9, 25, 65);
scene.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  powerPreference: "high-performance",
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
//renderer.outputEncoding = THREE.sRGBEncoding
//console.log(renderer.info)

debugObject.background = "#58509b";
renderer.setClearColor(debugObject.background);
gui.addColor(debugObject, "background").onChange(() => {
  renderer.setClearColor(debugObject.background);
});

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;
controls.minDistance = 35
//controls.minDistance = 20 //TEST CLIENTE
controls.maxDistance = 65;
controls.enablePan = false
controls.maxPolarAngle = Math.PI * 0.45;
controls.minPolarAngle = Math.PI * 0.3;
controls.minAzimuthAngle = 0;

/*
 */

/**
 * Utils Functions
 */
/*
for (const point of points) {
  point.element.addEventListener("click", (event) => {
    event.stopPropagation();
    //console.log(point.position)
    const stand = point.position;
    const coords = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    };
    new TWEEN.Tween(coords)
      .to({
        x: stand.x + point.animation.x,
        y: stand.y + point.animation.y,
        z: stand.z + point.animation.z,
      })
      .easing(TWEEN.Easing.Quadratic.In)
      .onUpdate(() => {
        camera.position.set(coords.x, coords.y, coords.z);
        //camera.lookAt()
      })
      .start();
  });
}
*/

/**
 * DROPDOWN
*/
const menuElement = document.querySelector('.menu');
const dropdown = document.querySelector('.header-menu');
const sectionDropdown = document.querySelector('.section-dropdown');

const toggleMenu = () => {

  let height = 399;
  //let height = 78;

  if(dropdown.classList.contains('close')){
    // CERRAR
    gsap.to(menuElement, {duration: .2, ease: "back.inOut(1)", height: "79px"})
    sectionDropdown.classList.remove('open')
    dropdown.classList.remove('close')
  }else{
    // ABRIR
    gsap.to(menuElement, {duration: .2, ease: "back.inOut(1)", height: "442px"})
    sectionDropdown.classList.add('open')
    dropdown.classList.add('close')
  }
}

dropdown.addEventListener('click', (event) => {
  event.stopPropagation();
  toggleMenu();
})
toggleMenu();


let menuStandElement = '';

if(menu){
  for (const option of optionsMenu) {
    option.element.addEventListener("click", (event) => {
      event.stopPropagation();
      const coords = {
        x: camera.position.x,
        y: camera.position.y,
        z: camera.position.z,
      };
      const animation = {
        x: option.animation.x,
        y: option.animation.y,
        z: option.animation.z,
      };
      const posicionStand = {
        x: option.lookAt.x,
        y: option.lookAt.y,
        z: option.lookAt.z,
      };

      //backToFlyView.parentElement.append(menuStand[option.menuStand].html());
      menuStandElement = document.querySelector(`.${option.menuStand}`)

      gsap.to(menuElement, {duration: 1, ease: "back.inOut(1)", x: -400})

      //Camera move
      new TWEEN.Tween(coords)
        .to({x:  animation.x, y: animation.y, z: animation.z}, 3500)
        .delay(500)
        .easing(TWEEN.Easing.Quadratic.InOut) // NEW -> TWEEN.Easing.Quadratic.InOut, OLD -> TWEEN.Easing.Quadratic.In
        .onStart(()=>{
          controls.enabled = false;
          //backToFlyView.parentElement.appendChild(menuStand[option.menuStand].html)

        })
        .onUpdate(() => {
          camera.position.set(coords.x, coords.y, coords.z);

          camera.lookAt(objetivo.position);

        })
        .onComplete(()=> {

          controls.enabled = true;
          controls.minDistance = 0;
          controls.enableZoom = false;

          console.log(option.menuStand)

          if(option.menuStand == 'nutricia' || option.menuStand == 'livanova')  {
            controls.minAzimuthAngle = 0.3;
            controls.maxAzimuthAngle = Math.PI * 0.6;
          }

          if(option.menuStand == 'sanofi' || option.menuStand == 'takeda'){
            controls.minAzimuthAngle = Math.PI * -0.6;
            controls.maxAzimuthAngle = -0.3;
          }

          if(option.menuStand == 'eisai'){
            controls.minAzimuthAngle = 1.5;
            controls.maxAzimuthAngle = Math.PI * 0.9;
          }

          if(option.menuStand == 'biocodex'){
            controls.minAzimuthAngle = Math.PI * -0.9;
            controls.maxAzimuthAngle = -1.5;
          }


          controls.target.copy( objetivo.position )//.add( lookDirection.multiplyScalar( 15 ) );

          gsap.to(menuStandElement, {duration: 1, ease: "back.inOut(1)", x: 0})

        })
        .start();
        // Camera Rotation

        new TWEEN.Tween(objetivo.position)
          .to({x: posicionStand.x, y: posicionStand.y, z: posicionStand.z}, 3500)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => {
            camera.lookAt(objetivo.position);
          })
          .onComplete(()=>{
          })
          .start();
    });
  }
}

const backToFlyView = document.querySelectorAll('.back')

for (let i = 0; i < backToFlyView.length; i++) {

  backToFlyView[i].addEventListener("click", (event) => {
    event.stopPropagation();
    const coords = {
      x: camera.position.x,
      y: camera.position.y,
      z: camera.position.z,
    };
    let zFlyView = -55
    if(coords.z > 0){
      zFlyView = 55;
    }

    gsap.to(menuStandElement, {duration: 1, ease: "back.inOut(1)", x: -400})

    new TWEEN.Tween(coords) // CAMARA
    .to({y: 25 , z: zFlyView}, 2300)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onStart(()=>{
      controls.enabled = false;
    })
    .onUpdate(() => {
      camera.position.set(coords.x, coords.y, coords.z);
    })
    .onComplete(()=> {

      controls.enabled = true;
      controls.minDistance = 35;
      controls.enableZoom = true;

      controls.minAzimuthAngle = '-Infinity';
      controls.maxAzimuthAngle = 'Infinity';

      controls.target.copy( objetivo.position )

      gsap.to(menuElement, {duration: 1, ease: "back.inOut(1)", x: 0})
    })
    .start();


    new TWEEN.Tween(objetivo.position) // OBJETIVO
      .to({x: 0, y: 0, z: 0}, 2300)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .onUpdate(() => {
        camera.lookAt(objetivo.position);
      })
      .onComplete(()=>{
      })
      .start();

  })


}


/**
 * Animate
 */
const clock = new THREE.Clock();

const tick = () => {
  const elapsedTime = clock.getElapsedTime();
  const delta = clock.getDelta();

  // Update controls
  controls.update();

  //Camera update
  //console.log(camera.position)
  //currentLookAt.lerp(newLookat, 0.015); //Mas pequeño mas lento
	//camera.lookAt(currentLookAt);

  //Points of interest
  /*
  for (const point of points) {
    const screenPosition = point.position.clone();
    screenPosition.project(camera);

    //raycaster.setFromCamera(screenPosition, camera)
    //const intersects = raycaster.intersectObjects(scene.children, true)
    /*
        if(intersects.length === 0)
        {
            point.element.classList.add('visible')
        }
        else
        {
            const intersectionDistance = intersects[0].distance
            const pointDistance = point.position.distanceTo(camera.position)

            if(intersectionDistance < pointDistance)
            {
                point.element.classList.remove('visible')
            }
            else
            {
                point.element.classList.add('visible')
            }
        }
        */
    /*
    const translateX = screenPosition.x * sizes.width * 0.5;
    const translateY = -screenPosition.y * sizes.height * 0.5;
    point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
    point.element.classList.add("visible"); //TEST SIN RAYCASTER
  }
  */

  // TWEEN
  TWEEN.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();
